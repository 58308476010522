import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Navigation from './components/Navigation/Navigation';
import Preloader from './components/Common/Preloader';
import HomePage from '../src/components/Pages/HomePage';
import Projects from './components/Pages/Projects';
import ProjectDetail from './components/Pages/ProjectDetail';
import Impressum from './components/Pages/Impressum';
import Datenschutz from './components/Pages/Datenschutz';
import Company from './components/Pages/Company';
import DarkFooter from './components/Common/DarkFooter';
import NavigationSticky from './components/Navigation/NavigationSticky';
import NavigationHome from './components/Navigation/NavigationHome';
import ErrorPage from './components/Pages/ErrorPage';
import Karriere from './components/Pages/Karriere';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isMobile: false,
            width: 0,
            height: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight, isMobile: window.innerWidth < 900 });
    }

    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    }

    hashLinkScroll = () => {
        const { hash } = window.location;
        
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
    }

    render() {
        return (
            <Router onUpdate={this.hashLinkScroll}>
                    <React.Fragment>
                        {this.state.loading ? <Preloader /> : ''}
                        {/* <Route path="/" render={(routeProps) => <Navigation {...routeProps} isMobile={this.state.isMobile}/>}  /> */}
                        {/* <Navigation isMobile={this.state.isMobile} /> */}
                        <Route path="/" exact render={(routeProps) => <NavigationHome {...routeProps} isMobile={this.state.isMobile} />} />
                        <Route path={["/projects", "/projects:type", "/project-details:id"]} exact render={(routeProps) => <Navigation {...routeProps} isMobile={this.state.isMobile} />} />
                        <Route path={["/impressum", "/datenschutz", "/unternehmen", "/karriere"]} exact render={(routeProps) => <NavigationSticky {...routeProps} isMobile={this.state.isMobile} />} />
                        
                        <div className="wrap-content">
                            <Switch>
                                <Route path="/" exact render={(props) => <HomePage {...props} isMobile={this.state.isMobile} />} />
                                <Route path="/projects" exact render={(props) => <Projects {...props} isMobile={this.state.isMobile} />}  />
                                <Route path="/projects:type" exact render={(props) => <Projects {...props} isMobile={this.state.isMobile} />}  />

                                {/* <Route path="/projects" exact component={<Projects  isMobile={this.state.isMobile} />}  />
                                <Route path="/projects:type" exact component={<Projects isMobile={this.state.isMobile} />}  /> */}
                                <Route path="/project-details:id" exact render={(props) => <ProjectDetail {...props} isMobile={this.state.isMobile} />}  />
                                <Route path="/impressum" exact render={(props) => <Impressum {...props} isMobile={this.state.isMobile} />}  />
                                <Route path="/datenschutz" exact render={(props) => <Datenschutz {...props} isMobile={this.state.isMobile} />}  />
                                <Route path="/unternehmen" exact render={(props) => <Company {...props} isMobile={this.state.isMobile} />}  />
                                <Route path="/karriere" exact render={(props) => <Karriere {...props} isMobile={this.state.isMobile} />}  />

                                <Route path="/404" component={ErrorPage}/>
                                <Redirect to="/404" />
                            </Switch>
                        </div>
                        <DarkFooter />
                    </React.Fragment>
            </Router>
        );
    }
}

export default App;
