import superAgent from 'superagent';
import superagentDefaults from 'superagent-defaults';
import superagentJsonapify from 'superagent-jsonapify';
import superagentPrefix from 'superagent-prefix';
import {BACKEND_URL} from '../environment';

const prefix = superagentPrefix(BACKEND_URL);
const superagent = superagentDefaults(superagentJsonapify(superAgent));

superagent.use(prefix);

export default superAgent;