import React from 'react';
import GoogleMapReact from 'google-map-react';
import 'isomorphic-fetch';

const CustomComponent = ({ text }) => <div><img src={require("../../assets/images/marker-red.png")} alt="map" style={{width: "45px"}} /></div>;

class Map extends React.Component {

    // 48°13'05.6"N 11°27'35.5"E
    static defaultProps = {
        center: {
            lat: 48.218208,
            lng: 11.459861
        },
        zoom: 15
    };

    buildCard = () => <div className="row">
        <div className="col-lg-6 col-md-12 col-xs-12">
            <div className="contact-info">
                <div className="upper-border">
                    <p><b>Ingenieurbüro für das Bauwesen</b></p>
                    <img src={require('../../assets/images/tbu_head_bg.jpg')} className="background-img" alt="tbu-hintergrund" />
                    <img src={require('../../assets/images/tbu_logo.png')} className="logo-img" alt="tbu-logo" />

                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-0 col-xs-0" />
                    <div className="col-lg-8 col-sm-12 col-xs-12">
                        <ul>
                            <li>

                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 col-xs-6 prefix" >
                                        <span>Adresse:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-6 col-xs-6" >
                                        <p>TBU Ingenieurbüro GmbH</p>
                                        <p>{this.props.contact_data.street}</p>
                                        <p>{this.props.contact_data.place}</p>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 col-xs-6 prefix" >
                                        <span>Tel:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-6 col-xs-6" >
                                        <p>{this.props.contact_data.phone_number}</p>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 col-xs-6 prefix" >
                                        <span>Fax:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-6 col-xs-6" >
                                        <p>{this.props.contact_data.fax}</p>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6 col-xs-6 prefix" >
                                        <span>E-Mail:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-6 col-xs-6" >
                                        <p>{this.props.contact_data.email}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bottom-border">
                </div>
            </div>
        </div>
    </div>;

    buildMobileContactInformation = () =>
        <div className="mobile-contact-information">
            <h4><b>TBU Ingenieurbüro GmbH</b></h4>
            <b> <i class="fa fa-map-marker"/> {this.props.contact_data.street + " " + this.props.contact_data.place}</b>
            <br/>
            <b> <i class="fa fa-envelope"/> {this.props.contact_data.email}</b>
            <br/>
            <b> <i class="fa fa-phone" /> {this.props.contact_data.phone_number}</b>
            <br/>
            <b> <i class="fa fa-fax"/> {this.props.contact_data.fax}</b>
        </div>;

    render() {
        return (
            <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                <div className="section-title">
                        <h4>So erreichen Sie uns</h4>
                        <h2><span>Kontaktinformationen</span></h2>
                        {
                            this.props.useMobileView && this.buildMobileContactInformation()
                        }
                    </div>
                <div id="map">
                    <div style={{ height: '100%', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyD2C-EEBGlbQAY8RhKtLhvrN6Ebmf9iATk' }}
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                        >
                            <CustomComponent
                                lat={48.218208}
                                lng={11.459861}
                                text="Marker"
                            />
                        </GoogleMapReact>
                    </div>
                </div>
                {
                    !this.props.useMobileView && this.buildCard()
                }

            </section>
        );
    }
}

export default Map;
