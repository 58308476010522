import React from 'react';
// reactstrap components
import { Container, Card, CardDeck, Row, CardBody, CardTitle, CardHeader, CardText } from "reactstrap";
import { wrapInRows } from "../../utils/basic_utils.js";

const Buero = props => {
    const numColumns = 3;

    return (
        <>
            <div className="section section-buero" id="section-buero">
                <Container>
                    <Row>
                        <h2 className="title">Büroausstattung</h2>
                    </Row>
                    {
                        wrapInRows(props.items, numColumns).map(
                            (row, index) =>
                            <Row className="card-row" key={index}>
                                <CardDeck>
                                    {
                                        row.map(
                                            (item, index) =>
                                                <Card key={index}>
                                                    <CardHeader>{item.category}</CardHeader>
                                                    <CardTitle>{item.title}</CardTitle>
                                                    <CardBody>
                                                        <CardText>
                                                        {item.description}
                                                        </CardText>
                                                    </CardBody>
                                                </Card>
                                        )
                                    }

                                </CardDeck>
                                </Row>
                        )
                    }

                    
                </Container>
            </div>
        </>
    );
}

export default Buero;
