// import getConfig from 'next/config';

// const {
//   publicRuntimeConfig: { BACKEND_URL, TESTS_BACKEND_URL },
//   serverRuntimeConfig: { HTTP_AUTH_USER, HTTP_AUTH_PASS },
// } = getConfig();

import {BACKEND_URL} from '../environment';

/**
 * Transforms backend projects data into frontend readable one.
 *
 * @param data
 * @returns {{}}
 */
export const project = (data) => ({
  title: data.title,
  id: data.id,
  imgUrl: data.imgUrl
});

/**
 * Transforms backend about Section data into frontend readable one.
 *
 * @param data
 * @returns {{}}
 */
export const aboutSection = (data) => ({
  id: data.id,
  uberschrift: data.obererText,
  text: data.text,
  untererText: data.untererText
});

const parseLinkText = (text) => {
  return text.split("\n").sort((a, b) => a.localeCompare(b)).map(
    e => {
      const sep = e.indexOf(' | ');
      return {
        name: sep === -1 ? e : e.substring(0, sep),
        link: sep === -1 ? null : e.substring(sep + 3)
      }
    }
  );
};

/**
 * Transforms backend homepage data into frontend readable one.
 *
 * @param data
 * @param included
 * @returns {{}}
 */
export const homepage = (data) => {
    const references = {
      architekten: parseLinkText(data.referenzen.architekten),
      kommunale_oeffentliche_auftraggeber: parseLinkText(data.referenzen.kommunale_oeffentliche_auftraggeber),
      private_auftraggeber: parseLinkText(data.referenzen.private_auftraggeber),
      investoren_und_bautraeger_sueddeutscher_raum: parseLinkText(data.referenzen.investoren_und_bautraeger_sueddeutscher_raum),
      gemein: parseLinkText(data.referenzen.diverse_gemeinnutzige_wohnungsbauunternehmen)
    };
    
    return {
      id: data.id,
      headerImage: BACKEND_URL + data.titel_bild.url,
      background_image: BACKEND_URL + data.hintergrundbild.url,
      about_section: {
        title: data.ueber_uns_ueberschrift,
        paragraphs: data.ueber_uns_paragraphen,
        text_below: data.ueber_uns_unterer_text,
      },
      icon_items: data.animierte_icons,
      news_projects_text: '',
      news_projects: data.aktuelles.map(item => {return {'description': item.beschreibung, 'src': BACKEND_URL + item.bild.url}}),
      services_and_projects_title: data.leistungen_und_projekte_ueberschrift,
      services_and_projects_items: data.leistungen_und_projekte.map(item => {
        return {
          'title': item.typ,
          'description': item.beschreibung,
          'src': BACKEND_URL + item.bild.url
        }
      }),
      team_section_bullets: data.team_stichpunkte.split('\n'),
      team_section_image_items: data.team.map(item => {
        return {
          'src': BACKEND_URL + item.bild.url,
          'description': item.beschreibung
        };
      }),
      team_text: '',
      reference_items: data.referenzen,
      references_pdf_link: BACKEND_URL + data.referenzen.pdf.url,
      references: references,
      contact_section: {
        email: data.anschrift.email,
        fax: data.anschrift.fax,
        phone_number: data.anschrift.telefon,
        street: data.anschrift.strasse,
        place: data.anschrift.ort,
      }
    };
};


/**
 * Transforms backend homepage data into frontend readable one.
 *
 * @param data
 * @param included
 * @returns {{}}
 */
export const projects = (data) => {
  return {
    id: data.id,
    headerImage: BACKEND_URL + data.titel_bild.url,
    projectsOverviewText: '',
    projectItems: data.projekt.map(item => {
      return {
        id: item.id,
        architects: item.architekten,
        bauherren: item.bauherren,
        description: item.beschreibung,
        projectType: item.typ,
        fertigstellung: item.fertigstellung,
        isNewsProject: item.istAktuell,
        images: sortByKey(item.bilder, "name").map(bild => BACKEND_URL + bild.url),
        quelle: item.quelle
      }
    }),
    projectTypes: data.projekt_typ.map(item => item.typ),
    projectTypeDescriptions: data.projekt_typ.map(item => {
      return {
        type: item.typ,
        description: item.beschreibung
      }
    })
  };
}

export const company = (data) => {
  const buroItems = data.bueroaustattung.map(item => {
    return {
      title: item.title,
      description: item.Beschreibung,
      category: item.kategorie,
    };
  });

  const geschaeftsfuhrungItems = data.geschaeftsfuehrung.map(item => {
    return {
      name: item.name,
      werdegang: item.werdegang,
      img:  BACKEND_URL + item.bild.url
    };
  });

  return {
    headerImage: "",
    leistungsData: {
      leistungen_oberer_text: data.leistungen_oberer_text,
      leistungen_punkte: data.leistungen_punkte.split('\n'),
      leistungen_unterer_text: data.leistungen_unterer_text,
    },
    tatigungsData: {
      tatigkeitsfelder_oberer_text: data.tatigkeitsfelder_oberer_text,
      tatigkeitsfelder_punkte: data.taetigkeitsfelder_punkte.split('\n')
    },
    buro_items: buroItems,
    geschaeftsfuhrungItems: geschaeftsfuhrungItems
  };
}

export const datenschutz = (data) => {
  return {
    datenschutzerklaerung: data.datenschutzerklaerung,
    haftung: data.haftung_fuer_links
  };
}

export const karriere = (data) => {
  const stellenanzeigen = data.stelle.map(
    e => {
      return {
        'beschreibung': e.stellenbeschreibung,
        'bezeichnung': e.stellenbezeichnung
      }
    }
  );

  return {
    'stellenanzeigen': stellenanzeigen,
    'karriereObererText': data.oberer_text
  };
}

export const impressum = (data) => {
  return {
    anschrift: data.Anschrift,
    registergericht: data.registergericht,
    geschaeftsfuehrer: data.geschaeftsfuehrer,
    haftungshinweis: data.haftungshinweis,
    haftpflichtversicherung: data.haftpflichtversicherung
  };
}

const sortByKey = (list, key) => {
  const compareByKey = (a,b) => a[key] > b[key] ? 1 : -1;
  return list.sort(compareByKey);
}
