import React from "react";

const Geschaeftsfuehrung = props => (
    <section id="geschaeftsfuehrung" className="section-geschaeftsfuehrung">
        <div className="container">
            <div className="section-title">
                <h3>Geschäftsführung</h3>
            </div>

            <div className="row">
                {
                    props.items.map(e => <div className="col">
                        <h5 className="name">
                            {e.name}
                        </h5>
                        <img className="image" src={e.img} />
                        <div className="werdegang-wrapper">
                            <div className="werdegang">
                                {e.werdegang}
                            </div>
                        </div>
                    </div>)
                }
            </div>

        </div>
    </section>
)

export default Geschaeftsfuehrung;