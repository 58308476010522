import React from 'react';


const MainBanner = props => {
    let pageHeader = React.createRef();
    let iconRef = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                let windowScrollIcon = 140 + (window.pageYOffset / window.innerHeight) * 140 * 0.7;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
                iconRef.current.style.transform = "translate3d(40%, " + windowScrollIcon + "%, 0)";
                // "translate3d(-50%," + windowScrollIcon + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });
    return (
        <>
            <div id="home" className="main-banner" ref={pageHeader}>
                <div className="centered" ref={iconRef}>
                    <img className="tbu-white-logo" src={require("../../assets/images/Logo_TBU_transparent.png")} alt="tbu-logo"/>
                    <h1><b>Ingenieurbüro für das Bauwesen</b></h1>
                </div>
                <div className="image-wrapper">
                    <img className="main-banner-background-image" src={props.image} alt="main-banner" />
                </div>
            </div>
        </>
    );
}

export default MainBanner;
