import React from "react";

import { Container, Row, Col } from "reactstrap";

function ImpressumHeader(props) {
  return (
    <>
      <div className="section section-impressum" id="section-impressum">
        <Container>
          <div className="section-title">
            <h2>Impressum</h2>
            <h4>Angaben gemäß § 5 TMG</h4>
          </div>
          <Row>
            <Col>
              <div className="description-container">
                <h4 className="description-title">Anschrift:</h4>
                <p className="paragraph">
                  {props.data.anschrift}
                </p>
              </div>
            </Col>
            <Col>
              <div className="description-container">
                <h4 className="description-title">Geschäftsführer:</h4>
                <p className="paragraph">
                  {props.data.geschaeftsfuehrer}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="description-container">
                <h4 className="description-title">Registergericht:</h4>
                <p className="paragraph">
                  {props.data.registergericht}
                </p>
              </div>
            </Col>
            <Col>
              <div className="description-container">
                <h4 className="description-title">Haftpflichtversicherung:</h4>
                <p className="description ">
                    {props.data.haftpflichtversicherung}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ImpressumHeader;

