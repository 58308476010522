import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

class Funfact extends React.Component {
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    };

    render() {
        return (
            <section className="funFacts-area ptb-80">
                <div className="container">
                    <div className="row">
                        {typeof this.props.items !== 'undefined' &&
                            this.props.items.map((item, index) => {
                                if (typeof item !== 'undefined') {
                                    return <div className="col-lg-3 col-md-6" key={index}>
                                        <div className="funFact">
                                            <i className={"fa " + item.icon}></i>
                                            <h2 className="count">
                                                <VisibilitySensor
                                                    onChange={this.onVisibilityChange}
                                                    offset={{
                                                        top: 10
                                                    }}
                                                    delayedCall
                                                >
                                                    <CountUp
                                                        start={0}
                                                        end={
                                                            this.state.didViewCountUp
                                                                ? item.anzahl
                                                                : 0
                                                        }
                                                        duration={6}
                                                    />
                                                </VisibilitySensor>
                                            </h2>
                                            <p>{item.icon_text}</p>
                                        </div>
                                    </div>;
                                } else {
                                    return <div/>;
                                }
                            }
                            )
                        }
                    </div>
                </div>
                <img className="bg-image" src={this.props.background_image} alt="bg" />
            </section>
        );
    }
}

export default Funfact;