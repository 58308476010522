import React from 'react';

import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
    Card, CardBody, CardImg, CardText
} from "reactstrap";
import SingleSwipeCarousel from "./SingleSwipeCarousel";



const Team = props => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = (activeIndex + 1) % props.items.length;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = (activeIndex + props.items.length - 1) % props.items.length;
        setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const buildTeamDescription = () => {
        return <div className="">
            <div className="section-title">
                <h2>Unser <span>Team</span></h2>
                <p>{props.text}</p>
            </div>

            <ul>
                {
                    props.bullets.map((b, index) =>
                        <li key={index}>
                            <i className="fa fa-angle-right"/>
                            <a> {b}</a>
                        </li>
                    )
                }

            </ul>
        </div>;
    }

    const buildTeamCarousel = () => {
        return <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators
                items={props.items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {props.items.map((item, index) => {
                return (
                    <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={index}
                    >
                        <div className="image">
                            <img src={item.src} alt="team-bild"/>
                            <div className="box">
                                <h5>{item.description}</h5>
                            </div>
                        </div>
                    </CarouselItem>
                );
            })}
            <a
                className="carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={e => {
                    e.preventDefault();
                    previous();
                }}
                role="button"
            >
                <i className="fa fa-chevron-left"></i>
            </a>
            <a
                className="carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={e => {
                    e.preventDefault();
                    next();
                }}
                role="button"
            >
                <i className="fa fa-chevron-right"></i>
            </a>
        </Carousel>;
    };

    const buildTeamCarouselMobile = () => SingleSwipeCarousel(
        props.items.map(item =>
          <div className="container card-container" key={item}>
            <Card>
              <div className="zoom-wrapper"><CardImg variant="top" src={item.src} alt="team-bild" /></div>
              <CardBody>
                <CardText>
                  {item.description}
                </CardText>
              </CardBody>
            </Card>
          </div>
        )
      );

    return (
        <section className="strategy-area" id="team">
            {
                <div className="container">
                        <div className="team-description">
                            {
                                buildTeamDescription(props)
                            }
                        </div>
                    </div>

            }
        </section>
    );
}

export default Team;