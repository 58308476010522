
import React from 'react';

class ContactForm extends React.Component {


    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
        ).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true });
                let formFields = Object.assign({}, this.state.formFields);
                formFields.name = '';
                formFields.email = '';
                formFields.phone = '';
                formFields.text = '';
                this.setState({ formFields });
            } else {
                console.log(res.status);
                console.log(res.body);
            }
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({ formFields });
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({ formFields });
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({ formFields });
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({ formFields });
    }

    onHideSuccess = () => {
        this.setState({ submitted: false });
    }

    successMessage = () => {
        if (this.state.submitted) {
            return (
                <div
                    className="alert alert-success alert-dismissible fade show"
                    style={{ marginTop: '14px' }}
                >
                    <strong>Vielen Dank!</strong> Ihre Nachricht wurde erfolgreich versendet.
                    <button
                        type="button"
                        className="close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }

    render() {
        return <section className="contact-form">
            <div className="container">
                <div className="section-title">
                    <h4>Gerne beantworten wir Ihre Fragen</h4>
                    <h2><span>Kontaktformular</span></h2>
                </div>
                <form id="contactForm" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    placeholder=""
                                    className="form-control"
                                    name="name"
                                    id="name"
                                    required={true}
                                    data-error="Please enter your name"
                                    value={this.state.formFields.name}
                                    onChange={this.nameChangeHandler}
                                />
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    placeholder=""
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    required={true}
                                    data-error="Please enter your email"
                                    value={this.state.formFields.email}
                                    onChange={this.emailChangeHandler}
                                />
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="number">Telefonnummer</label>
                                <input
                                    placeholder="*freiwillig"
                                    type="text"
                                    className="form-control"
                                    name="number"
                                    id="number"
                                    required={false}
                                    data-error="Please enter your number"
                                    value={this.state.formFields.phone}
                                    onChange={this.phoneChangeHandler}
                                />
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="message">Nachricht</label>
                                <textarea
                                    name="message"
                                    className="form-control"
                                    id="message"
                                    cols="30"
                                    rows="4"
                                    required={true}
                                    data-error="Write your message"
                                    value={this.state.formFields.text}
                                    onChange={this.textChangeHandler}
                                />
                                <div className="help-block with-errors"></div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <button type="submit" className="btn btn-primary">Nachricht abschicken</button>
                            {this.successMessage()}
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </form>
            </div>
        </section>;
    }
}

export default ContactForm;