/*eslint-disable*/
import React from "react";


function DarkFooter() {
  return (
    <footer className="footer">
        <ul className="ul-footer">
          <li className="nav-item">
            <a>
              © {new Date().getFullYear()} TBU
            </a>
          </li>
          <li className="nav-item">
            <a href="/impressum">
              Impressum
            </a>
          </li>
          <li className="nav-item">
            <a href="/datenschutz">
              Datenschutz
            </a>
          </li>
          {/* <li className="nav-item">
            <a href="/#references">
              Referenzen
            </a>
          </li>
          <li className="nav-item">
            <a href="/projects">
              Projekte
            </a>
          </li>
          <li className="nav-item">
            <a href="/unternehmen">
              Unternehmen
            </a>
          </li> */}
        </ul>

    </footer>
  );
}

export default DarkFooter;
