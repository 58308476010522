import React from 'react'

import { getProjectsPageData } from '../../api/drupalApi';

import Preloader from '../../components/Common/Preloader';
import ProjectsBanner from '../PageBanners/ProjectsBanner';
import Work from '../Common/Work';
import GoTop from '../Common/GoTop';

class Projects extends React.Component {

    state = {
        loading: true,
        projectsData: null
    };

    componentDidMount() {
        document.title = "TBU - Projekte";
        
        getProjectsPageData().then(data => {
            this.setState({ loading: false, projectsData: data.content });
        });
    }

    getFilterType = () => {
        const { type } = this.props.match.params;
        
        let filterType = null;
        if (typeof type !== 'undefined') {
            filterType = type.replace(':type=', '');
        }
        if (!this.state.loading && !this.state.projectsData.projectTypes.includes(filterType)) {
            filterType = null;
        }
        if (filterType !== null) {
            filterType = '.' + filterType;
        }
        return filterType;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ? <Preloader /> :
                    <>
                        <Work filterType={this.getFilterType()} projectTypes={['wohnungsbau', 'gewerbebau', 'oeffentliche_bauten', 'ingenieurbau', 'gutachten', 'sigeko']} 
                            projectItems={this.state.projectsData.projectItems}
                            overviewText={this.state.projectsData.projectsOverviewText}
                            projectTypeDescriptions={this.state.projectsData.projectTypeDescriptions}
                            />
                        {/* <Footer /> */}
                    </>
                }
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
                {/* <Footer/> */}
                
            </React.Fragment>
        );
    }
}

export default Projects;