import '../../../node_modules/bootstrap/scss/bootstrap.scss';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/animate.css';
import '../../assets/css/style.scss';
import '../../assets/css/responsive.scss';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link, withRouter } from 'react-router-dom';
import Sidebar from "react-sidebar";

import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";
import MobileNavbarStyle from './MobileNavbarStyle';

class NavigationSticky extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
            collapsed: true,
        };
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        this.elementId = document.getElementById("navbar");
        this.elementId.classList.add("is-sticky");
    }

    goToId = (e) => {
        window.location.hash = e;
        window.location.refresh(true);
    }

    scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    project_dropdown = () =>
        <UncontrolledDropdown nav>
            <DropdownToggle caret color="default" nav onClick={e => e.preventDefault()}>
                Projekte
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem href="/projects">
                    Alle Projekte
                </DropdownItem>
                <DropdownItem href="/projects:type=wohnungsbau">
                    Wohnungsbau
      </DropdownItem>
                <DropdownItem href="/projects:type=gewerbebau">
                    Gewerbebau
      </DropdownItem>
                <DropdownItem href="/projects:type=oeffentliche_bauten">
                    Öffentliche Bauten
        </DropdownItem>
                <DropdownItem href="/projects:type=ingenieurbau">
                    Ingenieurbau
      </DropdownItem>
                <DropdownItem href="/projects:type=gutachten">
                    Gutachten
      </DropdownItem>
                <DropdownItem href="/projects:type=sigeko">
                    SiGeKo
      </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>;

    isHomePage = () => window.location.pathname === '/';

    renderMenus = () => {
        return (
            this.isHomePage() ?
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <AnchorLink onClick={() => this.scrollTop()} className="nav-link" href="#home">Startseite</AnchorLink>
                    </li>
                    <li className="nav-item">
                        <AnchorLink offset={() => 60} className="nav-link" href="#section-news">Aktuelles</AnchorLink>
                    </li>
                    <li className="nav-item">
                        {this.project_dropdown()}
                    </li>
                    <li className="nav-item">
                        <AnchorLink offset={() => 0} className="nav-link" href="#section-services-projects">Leistungen</AnchorLink>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/unternehmen">Unternehmen</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/karriere">Karriere</Link>
                    </li>
                    <li className="nav-item">
                        <AnchorLink offset={() => 30} className="nav-link" href="#section-references">Referenzen</AnchorLink>
                    </li>
                    <li className="nav-item">
                        <AnchorLink offset={() => -40} className="nav-link" href="#contact">Kontakt</AnchorLink>
                    </li>
                </ul>
                :
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Startseite</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/#news">Aktuelles</Link>
                    </li>
                    <li className="nav-item">
                        {this.project_dropdown()}
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/#section-services-projects">Leistungen</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/unternehmen">Unternehmen</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/karriere">Karriere</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/#section-references">Referenzen</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/#contact">Kontakt</Link>
                    </li>
                </ul>
        );
    }


    render() {
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

        return (
            <div>
                <Sidebar
                    // sidebarClassName="sidebar"
                    // pullRight={true}
                    sidebar={
                        <div className="sidebar-content">
                            <div className="toggler-navbar-upper"></div>
                            <div className="mobile-logo-wrapper">
                                <img alt="TBU Logo" className="sidebar-navbar-logo" src={require("../../assets/images/tbu_logo.png")} />
                            </div>
                            <div className="border-bottom" />
                            <ul>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/">Startseite</Link>
                                </li>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/#section-news">Aktuelles</Link>
                                </li>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/projects">Projekte</Link>
                                </li>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/#section-services-projects">Leistungen</Link>
                                </li>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/unternehmen">Unternehmen</Link>
                                </li>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/karriere">Karriere</Link>
                                </li>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/#section-references">Referenzen</Link>
                                </li>
                                <li className="drawer-item">
                                    <Link className="drawer-link" to="/#contact">Kontakt</Link>
                                </li>
                            </ul>
                        </div>
                    }
                    open={this.state.sidebarOpen}
                    onSetOpen={this.onSetSidebarOpen}
                    styles={MobileNavbarStyle}
                >
                    <div />
                </Sidebar>
                <nav id="navbar" className="navbar navbar-expand-lg navbar-light bg-light header-sticky">
                    <div className="container">
                        {!this.props.isMobile &&
                            <>
                                <div className="navbar-upper" />
                                <Link
                                    className="navbar-brand"
                                    to="/"
                                >
                                    {
                                        <img alt="TBU Logo" className="navbar-logo" src={require("../../assets/images/tbu_logo.png")} />
                                    }
                                </Link>
                            </>
                        }
                        {
                            this.props.isMobile &&
                            <div className="toggler-wrapper">
                                <div className="toggler-navbar-upper" />
                                <img alt="TBU Logo" className="toggler-navbar-logo" src={require("../../assets/images/tbu_logo.png")} />
                                <button
                                    onClick={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                        }


                        <div className={classOne} id="navbarSupportedContent">
                            {this.renderMenus()}
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default withRouter(NavigationSticky);