import React from "react";

import { getImpressumPageData } from '../../api/drupalApi';

import ImpressumHeader from "../Common/ImpressumHeader.jsx";
import Preloader from '../../components/Common/Preloader';




class Impressum extends React.Component {
  state = {
    loading: true,
    projectsData: null
  };

  componentDidMount() {
    document.title = "TBU - Impressum";
    getImpressumPageData().then(data => {
      this.setState({ loading: false, data: data.content });
    });
  }


  render() {
    return (
      this.state.loading ? <Preloader /> :
        <>
          <div className="impressum-wrapper">
            <div className="main">
              <ImpressumHeader data={this.state.data}></ImpressumHeader>
            </div>
          </div>
        </>)
  }

}

export default Impressum;

