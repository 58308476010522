import React from "react";
import { Collapse } from 'react-collapse';

import {
    Card, CardBody, CardImg, CardTitle, CardDeck, CardText
} from "reactstrap";


import { wrapInRows } from "../../utils/basic_utils";
import SingleSwipeCarousel from "./SingleSwipeCarousel";


const ServicesAndProjects = props => {
    let content;
    if (props.isMobile) {
        content = SingleSwipeCarousel(
            props.items.map((item, index) => SaPCard(item, index))
        );
    } else {
        let rows = wrapInRows(props.items, 3);
        content =
            rows.map((row, rowNum) =>
                <CardDeck className="card-deck" key={rowNum}>
                    {
                        row.map((item, index) =>
                            SaPCard(item, index + ":" + rowNum)
                        )
                    }
                </CardDeck>
            );
    }

    return <section className="section section-services-projects" id="section-services-projects">
        <div className="container">
            <div className="section-title">
                <h2><span>Leistungen </span> und <span>Projekte</span></h2>
            </div>
            <p>{props.title}</p>
            {
                content
            }
        </div>
    </section >
}

const SaPCard = (item, index) => {
    const [isOpened, setOpen] = React.useState(false);
    return <Card key={index}>
        <Collapse isOpened={!isOpened}>
            <div className="zoom-wrapper">
                <a href={"/projects:type=" + item.title.toLowerCase().replace(' ', '-')}><CardImg variant="top" src={item.src} /></a>
            </div>
        </Collapse>
        <CardBody>
            <CardTitle>{item.title.replace('oeffentliche_bauten', 'Öffentliche Bauten').replace('sigeko', 'SiGeKo')}</CardTitle>
            <Collapse isOpened={isOpened}>
                <CardText>
                    {item.description}
                </CardText>
            </Collapse>
            <i className={isOpened ? "button fa fa-arrow-up" : "button fa fa-arrow-down"} onClick={() => setOpen(!isOpened)}> <a>{isOpened ? "weniger" : "mehr"} Informationen</a></i>
        </CardBody>
    </Card>;
}

export default ServicesAndProjects;