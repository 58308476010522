
export const wrapInRows = (elements, numColumns) => {
    const items = [];
    let row = [];
  
    for (let i = 0; i < elements.length; i++) {
      if (i % numColumns === 0 && i !== 0) {
        items.push(row);
        row = [];
      }
      row.push(elements[i])
    }
    items.push(row);
    return items;
  }