import React from "react";

import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { useHistory, Link  } from "react-router-dom";

const HalfScreenCarousel = (props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const images = props.images;

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = (activeIndex + 1) % images.length;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = (activeIndex + images.length - 1) % images.length;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const history = useHistory();
  
  return (
    <>
      <section
        className="section-halfscreen-carousel"
        id="section-halfscreen-carousel"
      >
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={images}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {images.map((item) => {
            return (
              <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={item}
              >
                <img src={item} alt="alt" />
              </CarouselItem>
            );
          })}
          <a
            className="carousel-control-prev"
            data-slide="prev"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              previous();
            }}
            role="button"
          >
            <i className="fa fa-chevron-left"></i>
          </a>

          <a
            className="carousel-control-next"
            data-slide="next"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              next();
            }}
            role="button"
          >
            <i className="fa fa-chevron-right"></i>
          </a>
        </Carousel>
        <a>
          <a className="prev-link"  onClick={() => {
            window.history.back();
          }}>
            <i className="fa fa-times"></i>
          </a>
        </a>
      </section>
    </>
  );
};

export default HalfScreenCarousel;
