import * as transforms from "../utils/transforms";
import request from "../utils/request";
import {BACKEND_URL} from '../environment';
const fetch = require("node-fetch");

const options = {
  method: "GET",
  // mode: "no-cors",
  headers: new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
    'Access-Control-Allow-Origin': '*'
  }),
};

export const getHomePageData = () =>
  fetch(BACKEND_URL + "/startseites/1")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.homepage(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getProjectsPageData = () =>
  fetch(BACKEND_URL + "/projektseites/1")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.projects(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getCompanyPageData = () =>
  fetch(BACKEND_URL + "/unternehmen/1")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.company(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getDatenschutzPageData = () =>
  fetch(BACKEND_URL + "/Datenschutzerklaerungs/1")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.datenschutz(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getKarriereData = () =>
  fetch(BACKEND_URL + "/karrieres/3")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.karriere(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });

export const getImpressumPageData = () =>
  fetch(BACKEND_URL + "/impressums/1")
    .then((res) => res.json())
    .then((res) => {
      return { content: transforms.impressum(res) };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Could not fetch the company data.", error);
    });
