import React from 'react';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

const About = props => {
    return (
        <section id="about" className="about-area-flat ptb-80 bg-f6f6f6">
            <div className="container">
                <div className="section-title">
                    <h2><span>Über uns</span></h2>
                    <h3>{props.data.title}</h3>
                </div>
                <div className="paragraphs">
                {
                    props.data.paragraphs
                }
                </div>
                <h4 className="subtitle">{props.data.text_below}</h4>
            </div>
        </section>
    );
}

export default About;