import React from "react";
import "react-image-lightbox/style.css";
import { Link } from "react-router-dom";
import mixitup from "mixitup";
import { Collapse } from "react-collapse";

const getDerivedStateFromProps = (props,states) => {
  if ((typeof props.location !== 'undefined') &&
      (typeof props.location.state !== 'undefined') &&
       (states.location_key !== props.location.key)) {
      let newState = props.location.state;
      newState.location_key = props.location.key;
      return newState;
  }
}

class Work extends React.Component {
  state = {
    isOpen: true,
  };

  getSubStringPosition = (string, subString, index) =>
    string.split(subString, index).join(subString).length;

  componentWillUnmount() {
    console.log("Work will unmounted!");
    this.state.mixer.destroy();
  }

  componentDidMount() {
    console.log("Work mounted!");
    let filterType = this.props.filterType;

    if (filterType === null) {
      filterType = this.props.projectTypes
        .map((p) => "." + p.toLowerCase() + "-project-item")
        .join(",");
    } else {
      filterType = filterType + "," + filterType + "-project-item";
    }
    
    var mixer = mixitup("#mix-wrapper", {
      animation: {
        effects: "fade rotateZ(0deg)",
        duration: 700,
      },
      classNames: {
        block: "programs",
        elementFilter: "filter-btn",
      },
      selectors: {
        target: ".mix-target",
      },
      load: {
        filter: filterType,
      },
    });

    this.setState({ filterType: filterType, mixer: mixer});
  }

  updateHistory(filterType) {
    var newUrl = window.location.protocol + "//" + window.location.host + '/projects:type=' + filterType;
    window.history.pushState({path:newUrl},'',newUrl);
    this.state.mixer.forceRender();
    this.forceUpdate();
  }

  render() {
    return (
      <section id="work" className="work-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>
              Unsere <span>Projekte</span>
            </h2>
            <p>{this.props.overviewText}</p>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="shorting-menu">
                <button
                  isOpen={true}
                  onClick={() => this.updateHistory("all")}
                  className="filter"
                  data-filter={this.props.projectTypes.map(
                    (p) => "." + p.toLowerCase() + "-project-item"
                  )}
                >
                  Alle
                </button>
                {this.props.projectTypes.map((t) => (
                  <button
                    isOpen={false}
                    className="filter"
                    onClick={() => this.updateHistory(t)}
                    data-filter={[
                      "." + t.toLowerCase(),
                      "." + t.toLowerCase() + "-project-item",
                    ]}
                  >
                    {t
                      .replace("oeffentliche_bauten", "Öffentliche Bauten")
                      .replace("sigeko", "SiGeKo")}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="shorting container">
          <div className="row m-0" id="mix-wrapper">
            <div className="col-lg-12 ">
              {this.state.filterType !== 'all' ? (
                this.props.projectTypeDescriptions.map((item, index) => (
                  <div className="container projects-description" key={index}>
                    <div
                      className={
                        "mix " + item.type.toLowerCase() + " p-0 mix-target"
                      }
                    >
                      <div>
                        {item.description.substring(
                          0,
                          this.getSubStringPosition(item.description, "\n", 5)
                        )}
                      </div>

                      <Collapse
                        containerHeight={123}
                        isOpened={this.state.isOpen}
                        initialStyle={{ height: "5000px", overflow: "visible" }}
                      >
                        {item.description.substring(
                          this.getSubStringPosition(item.description, "\n", 5) +
                            1
                        )}
                      </Collapse>
                      {this.state.isOpen && (
                        <div
                          className="project-description-show-less"
                          onClick={() => this.setState({ isOpen: false })}
                        >
                          <i className="fa fa-arrow-up"> weniger anzeigen</i>
                        </div>
                      )}
                      {!this.state.isOpen && (
                        <div
                          className="project-description-show-more"
                          onClick={() => this.setState({ isOpen: true })}
                        >
                          <i className="fa fa-arrow-down"> mehr anzeigen</i>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div />
              )}
            </div>

            {this.props.projectItems.map((item, index) => (
              <div
                className={
                  "col-lg-4 col-md-6 mix " +
                  item.projectType.toLowerCase() +
                  "-project-item p-0 mix-target"
                }
                key={item}
              >
                <a
                  href={"/project-details:id=" + item.id}
                  // to={{
                  //   pathname: "/project-details:id=" + item.id,
                  //   project: item,
                  // }}
                >
                  <div
                    className="single-work-x"
                    style={{ width: "auto", height: "auto" }}
                  >
                    <div className="project-image">
                      {this.props.useMobileView ? (
                        <img
                          src={item.images[0]}
                          alt="project-img"
                          style={{ width: "auto", height: "auto" }}
                        />
                      ) : (
                        <img src={item.images[0]} alt="project-img" />
                      )}
                    </div>
                    <div className="work-content">
                      <p>{item.description}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Work;
