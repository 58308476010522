import React from "react";


const createLinkElement = item => (
    <li className={item.link !== null ? "linkable" : ""}>
        <a onClick={()=> window.open(item.link, "_blank")}>
            {item.name}
        </a>
    </li>
);

const ReferencesList = props => {
    // const architekten = props.items.filter(e => e.type === 'Architekten').sort((a, b) => a.name.localeCompare(b.name));
    // const auftraggeber = props.items.filter(e => e.type === 'Auftraggeber').sort((a, b) => a.name.localeCompare(b.name));
    const subkategorienAuftraggeber = ['Kommunale und öffentliche Auftraggeber u.a.',
        'Private Auftraggeber u.a.', 'Renommierte Investoren und Bauträger im süddeutschen Raum, u.a.',
        'Diverse gemeinnützige Wohnungsbauunternehmen u.a.'];
    // console.log(props);

    return <section id="section-references"
        className="section section-references"
        data-background-color="gray"
    >
        <div className="section-title">
            <h2>Unsere <span>Referenzen</span></h2>
        </div>
        <div>
            <div className="row">
                <div className="list col">
                    <h2>Architekten</h2>
                    <ul>
                        {
                            props.references.architekten.map(e =>
                                createLinkElement(e)
                            )
                        }
                    </ul>
                </div>
                <div className="col">
                    <h2>Auftraggeber</h2>
                    <ul>
                        <div>
                            <h5>Kommunale und öffentliche Auftraggeber u.a.:</h5>
                            {
                                props.references.kommunale_oeffentliche_auftraggeber.map(e =>
                                    createLinkElement(e)
                                )
                            }
                        </div>
                        <div>
                            <h5>Private Auftraggeber u.a.:</h5>
                            {
                                props.references.private_auftraggeber.map(e =>
                                    createLinkElement(e)
                                )
                            }
                        </div>
                        <div>
                            <h5>Renommierte Investoren und Bauträger im süddeutschen Raum, u.a.:</h5>
                            {
                                props.references.investoren_und_bautraeger_sueddeutscher_raum.map(e =>
                                    createLinkElement(e)
                                )
                            }
                        </div>
                        <div>
                            <h5>Diverse gemeinnützige Wohnungsbauunternehmen u.a.:</h5>
                            {
                                props.references.gemein.map(e =>
                                    createLinkElement(e)
                                )
                            }
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        {/* <div className="references-link-subsection">
            <h4>Alle weiteren Referenzen finden Sie unter folgendem Link: <a className="pdf-link" href={props.pdfLink}> Referenzen.pdf</a></h4>
        </div> */}
    </section>
}

export default ReferencesList;
